.alert {
    position: absolute;
    width: 100%;
}
.bd-example {
    padding: 1.5rem;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    border-width: .2rem;
    border: solid #f7f7f9;
}

.alignleft {
	float: left;
}
.alignright {
	float: right;
}

.fileName {
    color:#2CA8FF
}

.container {
    position: relative;
}

.spinner-item {
    position: relative;
    top: 50%;
    left: 50%;
}

.spinner-container {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9050;
    background-color: hsla(0,0%,100%,.75);
    visibility: visible;
    opacity: 1;
    -webkit-transition: opacity .2s ease,visibility 0s;
    transition: opacity .2s ease,visibility 0s;
    -webkit-transition-delay: 0s,.3s;
    transition-delay: 0s,.3s;
}

.block-display {
    display: block;
}